// import Swiper bundle with all modules installed
import Swiper from "swiper/bundle";

// import styles bundle
import "swiper/css/bundle";

document
	.querySelectorAll("body:not(.is-admin) .sertificat-slider")
	.forEach(function (item) {
		let slider = new Swiper(item, {
			slidesPerView: "auto",
			spaceBetween: 12,
			breakpoints: {
				930: {
					slidesPerView: 3,
					spaceBetween: 10,
				},
				1500: { slidesPerView: 3, spaceBetween: 16 },
			},
		});
	});
