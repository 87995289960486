// import Swiper bundle with all modules installed
import Swiper from "swiper/bundle";

// import styles bundle
import "swiper/css/bundle";

document
	.querySelectorAll("body:not(.is-admin) .award-slider")
	.forEach(function (item) {
		new Swiper(item.querySelector(".award-slider__swiper"), {
			slidesPerView: "auto",
			spaceBetween: 12,
			breakpoints: {
				550: {
					spaceBetween: 12,
				},

				992: {
					spaceBetween: 10,
					slidesPerView: 3,
				},
				1500: {
					slidesPerView: 4,
					spaceBetween: 16,
				},
			},
			navigation: {
				nextEl: item.querySelector(".award-slider__next"),
				prevEl: item.querySelector(".award-slider__prev"),
			},
		});
	});
