// function onEntry(entry) {
// 	entry.forEach((change) => {
// 		if (change.isIntersecting) {
// 			change.target.classList.add("_show");
// 		}
// 	});
// }

// let options = {
// 	threshold: [0],
// };
// let observer = new IntersectionObserver(onEntry, options);
// let elements = document.querySelectorAll("h1 , .h1,.text-block, .top-and-img");

// for (let elm of elements) {
// 	// observer.observe(elm);
// 	elm.classList.add("_show");
// }

// Scroll trigger animations
import ScrollMagic from "scrollmagic";

const smController = new ScrollMagic.Controller();

class triggerElement {
	constructor(el) {
		this.container = el;
		this.scene = null;

		this.handleScroll();
		this.handleUpdate();
	}

	handleScroll() {
		this.scene = new ScrollMagic.Scene({
			triggerElement: this.container,
			triggerHook: 0.9,
			offset: 0,
			duration: 0,
			reverse: false,
		})
			.addTo(smController)
			.on("enter", () => {
				this.container.classList.add("_is-animated");
			})
			.on("leave", () => {
				this.container.classList.remove("_is-animated");
			});
	}

	handleUpdate() {
		window.addEventListener("resize", () => {
			this.update();
		});
	}

	update() {
		this.scene.refresh();
	}
}

document.querySelectorAll(".block,.top-and-img").forEach((el) => {
	new triggerElement(el);
});
