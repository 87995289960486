window.onscroll = function () {
	headerScroll();
};

let header = document.querySelector(".header");
let scrollPrev = window.scrollY;
// var sticky = header.offsetTop;

function headerScroll() {
	if (window.scrollY > 50) {
		header.classList.add("_scroll");
		if (scrollPrev > window.scrollY) {
			header.classList.add("_view");
		} else {
			header.classList.remove("_view");
		}
	} else {
		header.classList.remove("_scroll");
	}
	scrollPrev = window.scrollY;
}
