// import Swiper bundle with all modules installed
import Swiper from "swiper/bundle";

// import styles bundle
import "swiper/css/bundle";

document
	.querySelectorAll("body:not(.is-admin) .info-1")
	.forEach(function (item) {
		if (item.querySelector(".info-1__swiper")) {
			let slider = new Swiper(item.querySelector(".info-1__swiper"), {
				slidesPerView: "auto",
				spaceBetween: 8,
				breakpoints: {
					900: {
						spaceBetween: 12,
					},

					1130: {
						spaceBetween: 10,
						slidesPerView: 4,
					},

					1500: {
						spaceBetween: 16,
						slidesPerView: 4,
					},
				},
			});
		}
		if (item.querySelector(".info-1__slider-2")) {
			let pagination = item.querySelector(".info-1__slider-2-pagi");
			let frontTopSlider = new Swiper(
				item.querySelector(".info-1__slider-2"),
				{
					loop: true,
					setWrapperSize: true,
					speed: 700,
					spaceBetween: 12,
					longSwipes: "false",
					autoplay: {
						delay: 5000,
					},

					// creativeEffect: {
					// 	prev: {
					// 		shadow: true,
					// 		translate: ["-20%", 0, -1],
					// 	},
					// 	next: {
					// 		translate: ["100%", 0, 0],
					// 	},
					// },
					pagination: {
						el: pagination,
						clickable: true,
						type: "custom",
						renderCustom: function (swiper, current, total) {
							// Render the progress bar
							var progressBarHtml = "";

							// Render the pagination numbers
							var paginationHtml = "";
							for (var i = 0; i < total; i++) {
								var className = "swiper-pagination-bullet";
								if (i === current - 1) {
									className +=
										" swiper-pagination-bullet-active";
								}
								if (i < current - 1) {
									className +=
										" swiper-pagination-bullet-prev";
								}
								paginationHtml +=
									'<span class="' +
									className +
									'">' +
									"</span>";
							}

							// Combine the progress bar and pagination numbers
							return progressBarHtml + paginationHtml;
						},
					},
					on: {
						autoplayTimeLeft(s, time, progress) {
							pagination.style.setProperty(
								"--progress",
								1 - progress
							);
						},
					},
				}
			);
		}
	});
