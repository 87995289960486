function filter(block, filterValue, activepage, pagelenght, isFilter) {
	let items = block.querySelectorAll(".filter__item");
	let active = 1;
	let lenght = parseInt(activepage) * parseInt(pagelenght);

	if (isFilter) {
		items.forEach(function (item) {
			console.log(filterValue, item.dataset.filter);
			if (filterValue == "all" || item.dataset.filter == filterValue) {
				item.classList.remove("_filter-hidden");
				if (active > lenght) {
					item.classList.add("_page-hidden");
				} else {
					item.classList.remove("_page-hidden");
				}
				active++;
			} else {
				item.classList.add("_filter-hidden");
			}
		});
	} else {
		items.forEach(function (item) {
			console.log(item);
			if (active > lenght) {
				item.classList.add("_page-hidden");
			} else {
				item.classList.remove("_page-hidden");
			}
		});
	}
	if (block.querySelectorAll(".filter__item._page-hidden").length != 0) {
		block.classList.remove("_not-more");
	} else {
		block.classList.add("_not-more");
	}
}
document.querySelectorAll(".filter")?.forEach(function (block) {
	let activepage = 1;
	let pagelenght = block.dataset.page;
	let filters = block.querySelectorAll("button.filter__filter");
	let isFilter = filters.length;
	if (isFilter) {
		if (!block.querySelector(".filter__filter._active") && isFilter) {
			filters[0].classList.add("_active");
		}
		let filterValue = block.querySelector(".filter__filter._active").dataset
			.filter;
		let more = block.querySelector(".filter__more");

		filters.forEach(function (filterItem) {
			filterItem.addEventListener("click", function () {
				filters.forEach(function (it) {
					it.classList.remove("_active");
				});
				filterItem.classList.add("_active");
				activepage = 1;
				filterValue = block.querySelector(".filter__filter._active")
					?.dataset.filter;
				filter(block, filterValue, activepage, pagelenght, isFilter);

				if (
					block
						.querySelector(".swiper")
						?.classList.contains("special-slider2__swiper")
				) {
					block
						.querySelectorAll(".filter__item")
						.forEach(function (slide) {
							slide.classList.add("swiper-slide");
						});
					block
						.querySelectorAll("._filter-hidden")
						.forEach(function (slide) {
							slide.classList.remove("swiper-slide");
						});
				}
				block.querySelector(".swiper")?.swiper.slideTo(0);
			});
		});

		more?.addEventListener("click", function () {
			activepage++;
			filter(block, filterValue, activepage, pagelenght, isFilter);
		});
		filter(block, filterValue, 1, pagelenght, isFilter);
	}
});
