document.querySelectorAll("[data-popup]").forEach(function (item) {
	document.querySelectorAll(".popup").forEach(function (item) {
		item.classList.remove("_open");
	});
	item.addEventListener("click", function (e) {
		e.preventDefault();
		document.querySelector(this.dataset.popup).classList.add("_open");
	});
});
document
	.querySelectorAll(".popup__overlay,.popup__close")
	.forEach(function (item) {
		item.addEventListener("click", function (e) {
			e.preventDefault();
			this.closest(".popup").classList.remove("_open");
		});
	});
