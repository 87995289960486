import $ from "jquery";
$(function () {
	$(".header__menu-btn-wrap").click(function (e) {
		$(".menu").addClass("_open");
		setTimeout(function () {
			$(".menu").addClass("_open-anim");
		}, 10);
	});
	$(".menu__close,.menu__close-icon").click(function (e) {
		$(".menu").removeClass("_open");
		$(".menu").removeClass("_open-anim");
	});
	$(".menu__second-menu-back").click(function (e) {
		if (window.innerWidth <= 1100) {
			e.preventDefault();
			$(".menu__second-wrap").removeClass("_active");
		}
	});

	$(".menu__first-menu a").click(function (event) {
		if ($(this).data("second")) {
			event.preventDefault();
			$(".menu__first-menu a").removeClass("_active");
			$(this).addClass("_active");

			$(".menu__second-menu-back-category").html(
				`«${$(this).text().trim()}»`
			);
			$(".menu__second-menu-back").attr("href", $(this).attr("href"));
			$(".menu__second-wrap").removeClass("_active");
			$($(this).data("second")).addClass("_active");
		}
	});
});
