import $ from "jquery";
$(function () {
	$(".vacancy-preview").each(function () {
		let item = $(this);
		item.find(".vacancy-preview__top").click(function () {
			item.toggleClass("_open");

			if (item.hasClass("_open")) {
				item.find(".vacancy-preview__body").slideDown();
			} else {
				item.find(".vacancy-preview__body").slideUp();
			}
		});
	});
});
