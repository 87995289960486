document.querySelectorAll(".contact-map")?.forEach(function (map) {
	ymaps.ready(function () {
		let center = map.dataset.coord.split(",");

		let myMap = new ymaps.Map(
			map,
			{
				center: [
					parseFloat(center[0].trim()),
					parseFloat(center[1].trim()),
				],
				controls: ["zoomControl"],
				zoom: 16,
			},
			{
				searchControlProvider: "yandex#search",
			}
		);

		myMap.behaviors.disable("scrollZoom");

		//РЅР° РјРѕР±РёР»СЊРЅС‹С… СѓСЃС‚СЂРѕР№СЃС‚РІР°С…... (РїСЂРѕРІРµСЂСЏРµРј РїРѕ userAgent Р±СЂР°СѓР·РµСЂР°)
		if (
			/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent
			)
		) {
			//... РѕС‚РєР»СЋС‡Р°РµРј РїРµСЂРµС‚Р°СЃРєРёРІР°РЅРёРµ РєР°СЂС‚С‹
			myMap.behaviors.disable("drag");
		}
		myPlacemark = new ymaps.Placemark(
			[parseFloat(center[0].trim()), parseFloat(center[1].trim())],
			{
				hintContent: "",
				balloonContent: "",
			},
			{
				// iconLayout: "default#image",
				// iconImageHref: map.dataset.marker,
				// iconImageSize: [33, 33],
				// iconImageOffset: [-16, -16],
			}
		);
		myMap.geoObjects.add(myPlacemark);
	});
});
