// import Swiper bundle with all modules installed
import Swiper from "swiper/bundle";

// import styles bundle
import "swiper/css/bundle";

document
	.querySelectorAll("body:not(.is-admin) .photo-slider")
	.forEach(function (item) {
		let slider2 = new Swiper(
			item.querySelector(".swiper.photo-slider__thumb"),
			{
				watchSlidesProgress: true,
				spaceBetween: 8,
				loop: true,
				// Responsive breakpoints
				breakpoints: {
					1100: {
						slidesPerView: 5,
						spaceBetween: 20,
					},
					992: {
						slidesPerView: 4,
						spaceBetween: 10,
					},
					700: {
						slidesPerView: 3,
						spaceBetween: 17,
					},
					320: {
						slidesPerView: 1,
						spaceBetween: 0,
					},
				},
			}
		);
		let slider = new Swiper(item.querySelector(".photo-slider__main"), {
			slidesPerView: 1,
			spaceBetween: 16,
			effect: "fade",
			navigation: {
				nextEl: item.querySelector(".photo-slider__next"),
				prevEl: item.querySelector(".photo-slider__prev"),
			},
			thumbs: {
				swiper: slider2,
			},
		});
	});
