// import Swiper bundle with all modules installed
import Swiper from "swiper/bundle";

// import styles bundle
import "swiper/css/bundle";
document.querySelectorAll("[data-galleryslider]").forEach(function (slide) {
	slide.addEventListener("click", function (e) {
		let slider = e.target.dataset.galleryslider.split("|")[0];
		let slide = e.target.dataset.galleryslider.split("|")[1];
		document.querySelector(slider).classList.add("_open");
		document
			.querySelector(slider)
			.querySelectorAll(".swiper-slide")
			.forEach((slide) => {
				let img = slide.querySelector("img");
				if (img) {
					let aspect = img.naturalWidth / img.naturalHeight;
					if (aspect > 1.2) {
						img.classList.add("_horizontal");
					} else if (aspect < 0.8) {
						img.classList.add("_vertical");
					} else {
						img.classList.add("_square");
					}
				}
			});
		document.querySelector(slider).querySelector(".swiper").swiper.update();
		setTimeout(function () {
			document
				.querySelector(slider)
				.querySelector(".swiper")
				.swiper.slideToLoop(slide);
		}, 200);
		document
			.querySelector(slider)
			.querySelector(".swiper")
			.swiper.slideToLoop(slide);
		console.log(
			slider,
			document.querySelector(slider).querySelector(".swiper").swiper,
			Number.parseInt(slide)
		);
	});
});

document
	.querySelectorAll("body:not(.is-admin) .gallery-popup-row")
	.forEach(function (slider) {
		new Swiper(slider, {
			slidesPerView: "auto",
			spaceBetween: 10,
			slidesPerGroup: 1,
			centeredSlides: "true",
			centeredSlidesBounds: "true",
			loop: "true",
			loopAdditionalSlides: 6,
			navigation: {
				nextEl: slider.querySelector(".gallery-popup-row__next"),
				prevEl: slider.querySelector(".gallery-popup-row__prev"),
			},
			mousewheel: {
				forceToAxis: true,
			},
			breakpoints: {},
		});

		slider.querySelectorAll(".swiper-slide").forEach(function (slide) {
			$(slide).css(
				"max-width",
				slide.querySelector("img,video").width + "px"
			);
		});
		window.addEventListener(
			"resize",
			function (event) {
				slider
					.querySelectorAll(".swiper-slide")
					.forEach(function (slide) {
						$(slide).css(
							"max-width",
							slide.querySelector("img,video").width + "px"
						);
					});
			},
			true
		);
	});
document
	.querySelectorAll(".gallery-row, .gallery-popup-row")
	.forEach(function (slider) {
		slider.querySelectorAll(".swiper-slide").forEach(function (slide) {
			let img = slide.querySelector("img");
			if (img) {
				let aspect = img.naturalWidth / img.naturalHeight;
				if (aspect > 1.2) {
					img.classList.add("_vertical");
				} else if (aspect < 0.8) {
					img.classList.add("_horizontal");
				} else {
					img.classList.add("_square");
				}
			}
		});
	});
document.querySelectorAll(".gallery-row").forEach(function (slider) {
	new Swiper(slider, {
		slidesPerView: "auto",
		spaceBetween: 8,
		slidesPerGroup: 1,
		centeredSlides: "true",
		centeredSlidesBounds: "true",
		loop: "true",
		loopAdditionalSlides: 2,
		navigation: {
			nextEl: slider.querySelector(".gallery-row__next"),
			prevEl: slider.querySelector(".gallery-row__prev"),
		},
		mousewheel: {
			forceToAxis: true,
		},
		breakpoints: {
			720: {
				spaceBetween: 12,
				slidesPerView: "auto",
			},
			1500: {
				spaceBetween: 16,
				slidesPerView: "auto",
			},
		},
	});
});
document.querySelectorAll(".gallery-popup-row").forEach(function (slider) {
	new Swiper(slider, {
		slidesPerView: "auto",
		spaceBetween: 8,
		slidesPerGroup: 1,
		centeredSlides: "true",
		centeredSlidesBounds: "true",
		loop: "true",
		observeParents: "true",
		observeSlideChildren: "true",
		observer: "true",
		loopAdditionalSlides: 2,
		navigation: {
			nextEl: slider.querySelector(".slider-next-btn"),
			prevEl: slider.querySelector(".slider-prev-btn"),
		},
		mousewheel: {
			forceToAxis: true,
		},
		breakpoints: {
			720: {
				spaceBetween: 12,
				slidesPerView: "auto",
			},
			1500: {
				spaceBetween: 16,
				slidesPerView: "auto",
			},
		},
	});
});
setTimeout(function () {
	document
		.querySelectorAll(".gallery-row, .gallery-popup-row")
		.forEach(function (slider) {
			slider.querySelectorAll(".swiper-slide").forEach(function (slide) {
				let img = slide.querySelector("img");
				if (img) {
					img.classList.remove("_vertical");
					img.classList.remove("_horizontal");
					img.classList.remove("_square");
					let aspect = img.naturalWidth / img.naturalHeight;

					if (aspect > 1.2) {
						img.classList.add("_horizontal");
					} else if (aspect < 0.8) {
						img.classList.add("_vertical");
					} else {
						img.classList.add("_square");
					}
				}
			});
		});
}, 6000);
setTimeout(function () {
	document
		.querySelectorAll(".gallery-row, .gallery-popup-row")
		.forEach(function (slider) {
			slider.querySelectorAll(".swiper-slide").forEach(function (slide) {
				let img = slide.querySelector("img");
				if (img) {
					img.classList.remove("_vertical");
					img.classList.remove("_horizontal");
					img.classList.remove("_square");
					let aspect = img.naturalWidth / img.naturalHeight;

					if (aspect > 1.2) {
						img.classList.add("_horizontal");
					} else if (aspect < 0.8) {
						img.classList.add("_vertical");
					} else {
						img.classList.add("_square");
					}
				}
			});
		});
}, 1000);
setTimeout(function () {
	document
		.querySelectorAll(".gallery-row, .gallery-popup-row")
		.forEach(function (slider) {
			slider.querySelectorAll(".swiper-slide").forEach(function (slide) {
				let img = slide.querySelector("img");
				if (img) {
					img.classList.remove("_vertical");
					img.classList.remove("_horizontal");
					img.classList.remove("_square");
					let aspect = img.naturalWidth / img.naturalHeight;

					if (aspect > 1.2) {
						img.classList.add("_horizontal");
					} else if (aspect < 0.8) {
						img.classList.add("_vertical");
					} else {
						img.classList.add("_square");
					}
				}
			});
		});
}, 3000);
