// import Swiper bundle with all modules installed
import Swiper from "swiper/bundle";
import $ from "jquery";
// import styles bundle
import "swiper/css/bundle";

$(document.body).append($(".popup").detach());
let bgwrap = "gray";
if (!$("#params").length) {
	bgwrap = $(".block[data-bg]").first().data("bg")
		? $(".block[data-bg]").first().data("bg")
		: "gray";

	$("body").append(`<div id="params" data-bg="${bgwrap}"></div>`);
}
if (document.querySelectorAll("body.is-admin").length == 0) {
	$(".block-wrapper").each(function () {
		let $wrap = $(this);
		if ($wrap.find(".text").length) {
			$wrap
				.find(".text .text-block, .text .text-aside")
				.each(function () {
					let $it = $(this);
					console.log($it);
					$wrap.before($it);
					$it.wrap('<div class="block-wrapper"></div>');
				});
			$wrap.remove();
		}
	});
	document.querySelectorAll(".tabs-slider").forEach(function (item) {
		if (!item.closest(".is-admin")) {
			let tabs = item.querySelectorAll(".tabs-slider__tab");
			let isActive = -1;

			let slider = new Swiper(
				item.querySelector(".tabs-slider__swiper"),
				{
					slidesPerView: 1,
					spaceBetween: 15,
					allowTouchMove: true,
					autoHeight: true,
					slideToClickedSlide: true,
				}
			);
			slider.on("slideChange", function () {
				tabs.forEach(function (tab) {
					if (slider.activeIndex == tab.dataset.slide) {
						tab.classList.add("_active");
					} else {
						tab.classList.remove("_active");
					}
				});
			});
			tabs?.forEach(function (tab) {
				tab.addEventListener("click", function () {
					slider.slideTo(tab.dataset.slide);
				});
				if (tab.classList.contains("_active")) {
					isActive = tab.dataset.slide;
				}
			});
			if (isActive == -1) {
				tabs[0]?.classList.add("_active");
			} else {
				slider.slideTo(isActive);
			}
		}
	});

	document
		.querySelectorAll(".block-wrapper:has(.text-block)")
		.forEach(function (item) {
			if (!item.closest(".is-admin")) {
				if (!item.closest(".text")) {
					let bg = bgwrap;

					if (item.querySelector(".block")) {
						bg = item.querySelector(".block").dataset.bg
							? item.querySelector(".block").dataset.bg
							: bgwrap;
					} else {
						bg = bgwrap;
					}
					$(item)
						.prev()
						.nextAll(
							".block-wrapper:has(.text-block), .block-wrapper:has(.aside-block)"
						)
						.wrapAll(
							`<div class="block-wrapper"><div class="block 1111 text" data-bg="${bg}"><div class="inner"><div class="text__row"><div class="text__content"></div></div></div></div></div>`
						);
				}
				item.classList.remove("block-wrapper");
			}
		});

	$(".text__row").each(function () {
		let $th = $(this);

		if ($th.find(".aside-block").length) {
			if (!$th.find(".text__aside").length) {
				$(this).append(
					'<div class="text__aside"><div class="text__aside-sticky"></div></div>'
				);
			}
		}
	});
	$(".text-block p, .text-block h3, .text-block .h3").each(function () {
		console.log($(this), $(this).text().trim().length);
		if ($(this).text().trim().length == 0) {
			console.log("sss");

			$(this).remove();
		}
	});

	$(".text__row").each(function () {
		let $th = $(this);
		if ($th.find(".aside-block").length) {
			if (!$th.find(".text__aside").length) {
				$(this).append(
					'<div class="text__aside"><div class="text__aside-sticky"></div></div>'
				);
			}
		}
	});
	$(".text__content .block").each(function () {
		$(this).removeClass("block");
	});
	$(".text__content > div").each(function () {
		console.log($(this));
		console.log($(this).text().trim().length);
		console.log($(this).find("img").length);
		if (
			$(this).text().trim().length == 0 &&
			$(this).find("img").length == 0
		) {
			$(this).remove();
		}
	});

	document.querySelectorAll(".text__row").forEach(function (item) {
		if (item.querySelector(".text__aside-sticky")) {
			let aside = item.querySelector(".text__aside-sticky");
			item.querySelectorAll(".aside-block").forEach(function (block) {
				block
					.closest(".block-wrapper")
					?.classList.remove("block-wrapper");
				aside.appendChild(block.cloneNode(true));
			});
		}
	});

	document
		.querySelectorAll("body:not(.is-admin)  .photo-slider")
		.forEach(function (item) {
			new Swiper(item.querySelector(".photo-slider__swiper"), {
				slidesPerView: 1,
				spaceBetween: 15,
				pagination: {
					type: "custom",
					el: item.querySelector(".photo-slider__pagi"),
					renderCustom: function (swiper, current, total) {
						return (
							String(current).padStart(2, "0") +
							"/" +
							String(total).padStart(2, "0")
						);
					},
				},

				navigation: {
					nextEl: item.querySelector(".photo-slider__next"),
					prevEl: item.querySelector(".photo-slider__prev"),
				},
			});
		});
}
