function declOfNum(number, words) {
	return words[
		number % 100 > 4 && number % 100 < 20
			? 2
			: [2, 0, 1, 1, 1, 2][number % 10 < 5 ? Math.abs(number) % 10 : 5]
	];
}
/* SLIDE UP */
let slideUp = (target, duration = 500) => {
	target.style.transitionProperty = "height, margin, padding";
	target.style.transitionDuration = duration + "ms";
	target.style.boxSizing = "border-box";
	target.style.height = target.offsetHeight + "px";
	target.offsetHeight;
	target.style.overflow = "hidden";
	target.style.height = 0;
	target.style.paddingTop = 0;
	target.style.paddingBottom = 0;
	target.style.marginTop = 0;
	target.style.marginBottom = 0;
	window.setTimeout(() => {
		target.style.display = "none";
		target.style.removeProperty("height");
		target.style.removeProperty("padding-top");
		target.style.removeProperty("padding-bottom");
		target.style.removeProperty("margin-top");
		target.style.removeProperty("margin-bottom");
		target.style.removeProperty("overflow");
		target.style.removeProperty("transition-duration");
		target.style.removeProperty("transition-property");
		//alert("!");
	}, duration);
};

/* SLIDE DOWN */
let slideDown = (target, duration = 500) => {
	target.style.removeProperty("display");
	let display = window.getComputedStyle(target).display;
	if (display === "none") display = "block";
	target.style.display = display;
	let height = target.offsetHeight;
	target.style.overflow = "hidden";
	target.style.height = 0;
	target.style.paddingTop = 0;
	target.style.paddingBottom = 0;
	target.style.marginTop = 0;
	target.style.marginBottom = 0;
	target.offsetHeight;
	target.style.boxSizing = "border-box";
	target.style.transitionProperty = "height, margin, padding";
	target.style.transitionDuration = duration + "ms";
	target.style.height = height + "px";
	target.style.removeProperty("padding-top");
	target.style.removeProperty("padding-bottom");
	target.style.removeProperty("margin-top");
	target.style.removeProperty("margin-bottom");
	window.setTimeout(() => {
		target.style.removeProperty("height");
		target.style.removeProperty("overflow");
		target.style.removeProperty("transition-duration");
		target.style.removeProperty("transition-property");
	}, duration);
};

/* TOOGLE */
var slideToggle = (target, duration = 500) => {
	if (window.getComputedStyle(target).display === "none") {
		return slideDown(target, duration);
	} else {
		return slideUp(target, duration);
	}
};

document.querySelectorAll(".traveline").forEach(function (traveline) {
	let adultPlus = traveline.querySelector(".traveline__adults-plus");
	let adultMinus = traveline.querySelector(".traveline__adults-minus");
	let adultCounter = traveline.querySelector(".traveline__adults-count");
	let childsAdd = traveline.querySelectorAll(".traveline__child-drop-add");
	let childsList = traveline.querySelector(".traveline__childs");
	let guestCountWrap = traveline.querySelector(".traveline__guest");
	let dateWrap = traveline.querySelector(".traveline__input-date-wrap");
	let guestCount = traveline.querySelector(".traveline__guest-count");
	let childDrop = traveline.querySelector(".traveline__child-drop");
	let childDropVisible = false;
	let childDropHead = traveline.querySelector(".traveline__child-drop-head");
	let inputDate = traveline.querySelector(".traveline__input-date");
	let childDropList = traveline.querySelector(".traveline__child-drop-list");
	document.addEventListener("click", (e) => {
		const withinBoundaries = e.composedPath().includes(guestCountWrap);

		if (withinBoundaries) {
		} else {
			childDropVisible = false;
			guestCountWrap.classList.remove("_open");
		}
	});

	guestCount.addEventListener("click", function () {
		if (childDropVisible) {
			childDropVisible = false;
			guestCountWrap.classList.remove("_open");
		} else {
			childDropVisible = true;
			guestCountWrap.classList.add("_open");
		}
	});
	$(inputDate).daterangepicker(
		{
			linkedCalendars: $(inputDate).hasClass("_single"),
			opens: "right",
			autoApply: true,
			startDate: moment(),
			endDate: moment().add(1, "day"),
			alwaysShowCalendars: true,
			locale: {
				format: "DD.MM.YYYY",
				separator: " - ",
				applyLabel: "Apply",
				cancelLabel: "Cancel",
				fromLabel: "From",
				toLabel: "To",

				customRangeLabel: "Custom",
				weekLabel: "W",
				daysOfWeek: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
				monthNames: [
					"Январь",
					"Февраль",
					"Март",
					"Апрель",
					"Мая",
					"Июнь",
					"Июль",
					"Август",
					"Сентябрь",
					"Октябрь",
					"Ноябрь",
					"Декабрь",
				],
				firstDay: 1,
			},
		},
		function (start, end, label) {
			console.log(
				"A new date selection was made: " +
					start.format("YYYY-MM-DD") +
					" to " +
					end.format("YYYY-MM-DD")
			);
		}
	);
	$(inputDate).on("hide.daterangepicker", function (ev, picker) {
		dateWrap.classList.remove("_open");
	});
	$(inputDate).on("show.daterangepicker", function (ev, picker) {
		dateWrap.classList.add("_open");
		body.style.setProperty(
			"--calendar-width",
			$(inputDate).innerWidth() + "px"
		);
	});
	childDropHead.addEventListener("click", function () {
		childDrop.classList.toggle("_open");
		slideToggle(childDropList, 300);
	});
	function travelineGuestCount() {
		console.log(childsList.querySelectorAll(".traveline__child").length);
		if (childsList.querySelectorAll(".traveline__child").length) {
			guestCount.value = `${adultCounter.innerText} ${declOfNum(
				adultCounter.innerText,
				["взрослый", "взрослых", "взрослых"]
			)}, ${
				childsList.querySelectorAll(".traveline__child").length
			}  ${declOfNum(
				childsList.querySelectorAll(".traveline__child").length,
				["ребенок", "детей", "детей"]
			)}`;
		} else {
			guestCount.value = `${adultCounter.innerText} ${declOfNum(
				adultCounter.innerText,
				["взрослый", "взрослых", "взрослых"]
			)}`;
		}
	}
	childsList.onclick = function (e) {
		let target = e.target;
		console.log(target.className);
		if (target.className == "traveline__child-delete") {
			target.closest(".traveline__child").remove();
			travelineGuestCount();
		}
	};
	childsAdd.forEach(function (add) {
		add.addEventListener("click", function () {
			childsList.insertAdjacentHTML(
				"beforeend",
				`<div class="traveline__child">
					<input  class="traveline__child-inpt" type="hidden" value="${
						add.querySelector("span").innerText
					}" name="${
					guestCount.dataset.childname
						? guestCount.dataset.childname
						: "childname[]"
				}"/>
					<div class="traveline__child-title">${add.querySelector("span").innerText}</div>
					<div class="traveline__child-delete">
						<svg class="svg-icon">
						<use xlink:href="sprite.svg#delete"></use>
						</svg>
					</div>
					</div>`
			);
			travelineGuestCount();
		});
	});

	adultPlus.addEventListener("click", function () {
		adultCounter.innerText = ++adultCounter.innerText;
		travelineGuestCount();
	});
	adultMinus.addEventListener("click", function () {
		adultCounter.innerText =
			adultCounter.innerText > 1
				? --adultCounter.innerText
				: adultCounter.innerText;
		travelineGuestCount();
	});
	traveline
		.querySelectorAll(".traveline__child-delete")
		.forEach(function (btn) {
			btn.addEventListener("click", function () {
				this.closest("");
			});
		});
});
