function mapResize() {
	document.querySelectorAll(".map").forEach(function (map) {
		let topBtn = map.querySelector(".map__map-legends-top");
		let scroll = map.querySelector(".map__map-legends");
		let bottomBtn = map.querySelector(".map__map-legends-bottom");
		let mapImg = map.querySelector(".map__map-img");

		scroll.style.height = mapImg.clientHeight - 120 + "px";
		if (scroll.scrollTop <= 0) {
			topBtn.classList.add("_disabled");
		} else {
			topBtn.classList.remove("_disabled");
		}
		if (scroll.scrollHeight - 10 > scroll.scrollTop + scroll.offsetHeight) {
			bottomBtn.classList.remove("_disabled");
		} else {
			bottomBtn.classList.add("_disabled");
		}
	});
}

if (document.querySelectorAll(".map")) {
	mapResize();
	window.addEventListener(
		"resize",
		function (event) {
			mapResize();
		},
		true
	);
	setTimeout(function () {
		mapResize();
	}, 1000);
	setTimeout(function () {
		mapResize();
	}, 2000);
	setTimeout(function () {
		mapResize();
	}, 3000);
	setTimeout(function () {
		mapResize();
	}, 6000);
	document.querySelectorAll(".map").forEach(function (map) {
		let topBtn = map.querySelector(".map__map-legends-top");
		let mapColumn = map.querySelector(".map__map-column");
		let mapWrap = map.querySelector(".map__map-wrap");
		let scroll = map.querySelector(".map__map-legends");
		let bottomBtn = map.querySelector(".map__map-legends-bottom");
		let toggle = map.querySelector(".map__map-resize-btn");
		toggle.addEventListener("click", function () {
			if (map.classList.contains("_open")) {
				map.classList.remove("_open");
				mapColumn.style.width = "initial";
				mapColumn.style.height = "initial";
			} else {
				mapColumn.style.width = mapWrap.offsetWidth + "px";
				mapColumn.style.height = mapWrap.offsetHeight + "px";
				mapColumn.style.setProperty(
					"--maptop",
					mapWrap.offsetTop - window.scrollY + "px"
				);
				mapColumn.style.setProperty(
					"--mapleft",
					mapWrap.offsetLeft + "px"
				);
				map.classList.add("_open");
			}
		});
		scroll.addEventListener("scroll", function (event) {
			if (scroll.scrollTop <= 0) {
				topBtn.classList.add("_disabled");
			} else {
				topBtn.classList.remove("_disabled");
			}
			if (
				scroll.scrollHeight - 10 >
				scroll.scrollTop + scroll.offsetHeight
			) {
				bottomBtn.classList.remove("_disabled");
			} else {
				bottomBtn.classList.add("_disabled");
			}
		});
		topBtn.addEventListener("click", function () {
			let scrollPosition = scroll.scrollTop - 100;

			scroll.scrollTo({
				top: scrollPosition,
				left: 0,
				behavior: "smooth",
			});
		});
		bottomBtn.addEventListener("click", function () {
			let scrollPosition = scroll.scrollTop + 100;
			scroll.scrollTo({
				top: scrollPosition,
				left: 0,
				behavior: "smooth",
			});
		});
	});
}
