// import Swiper bundle with all modules installed
import Swiper from "swiper/bundle";

// import styles bundle
import "swiper/css/bundle";

document
	.querySelectorAll("body:not(.is-admin) .dashbord")
	.forEach(function (item) {
		let slider2 = new Swiper(item.querySelector(".dashbord__slider "), {
			watchSlidesProgress: true,
			spaceBetween: 200,
			loop: true,
			slidesPerView: 1,
			allowTouchMove: false,
			preventClicks: false,
			preventClicksPropagation: false,
			navigation: {
				nextEl: item.querySelector(".dashbord__prev"),
				prevEl: item.querySelector(".dashbord__next"),
			},
		});
		let slider = new Swiper(item.querySelector(".dashbord__bg"), {
			slidesPerView: 1,
			spaceBetween: 16,
			effect: "fade",
			loop: true,
			allowTouchMove: false,

			thumbs: {
				swiper: slider2,
			},
		});
		item.querySelector(".dashbord__close").addEventListener(
			"click",
			function () {
				item.classList.remove("_open");
			}
		);
		document.querySelectorAll("[data-dashboard]").forEach(function (i) {
			i.addEventListener("click", (event) => {
				event.preventDefault();
				item.classList.add("_open");
				slider2.slideToLoop(i.dataset.dashboard);
				slider.slideToLoop(i.dataset.dashboard);
			});
		});
	});
