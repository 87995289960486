// import Swiper bundle with all modules installed
import Swiper from "swiper/bundle";

// import styles bundle
import "swiper/css/bundle";

document
	.querySelectorAll("body:not(.is-admin) .object-slider")
	.forEach(function (item) {
		let length = item.querySelectorAll(
			".object-slider .object-preview"
		).length;
		item.querySelectorAll(".object-preview").forEach((slide) => {
			slide.addEventListener("mouseover", function () {
				slide.classList.add("_hover");
			});
			slide.addEventListener("mouseout", function () {
				slide.classList.remove("_hover");
			});
		});
		item.style.setProperty("--lenght", length);
		// new Swiper(item.querySelector(".object-slider__swiper"), {
		// 	slidesPerView: 1,
		// 	spaceBetween: 12,
		// 	breakpoints: {
		// 		550: {
		// 			slidesPerView: 2,
		// 			spaceBetween: 12,
		// 		},

		// 		992: {
		// 			slidesPerView: 3,
		// 			spaceBetween: 10,
		// 		},
		// 		1100: {
		// 			slidesPerView:
		// 				item.querySelectorAll(
		// 					".object-slider__swiper .swiper-slide"
		// 				).length > 3
		// 					? 3
		// 					: item.querySelectorAll(
		// 							".object-slider__swiper .swiper-slide"
		// 					  ).length,
		// 			spaceBetween: 16,
		// 		},

		// 		1300: {
		// 			slidesPerView:
		// 				item.querySelectorAll(
		// 					".object-slider__swiper .swiper-slide"
		// 				).length > 4
		// 					? 4
		// 					: item.querySelectorAll(
		// 							".object-slider__swiper .swiper-slide"
		// 					  ).length,
		// 			spaceBetween: 16,
		// 		},
		// 		1400: {
		// 			slidesPerView:
		// 				item.querySelectorAll(
		// 					".object-slider__swiper .swiper-slide"
		// 				).length > 5
		// 					? 5
		// 					: item.querySelectorAll(
		// 							".object-slider__swiper .swiper-slide"
		// 					  ).length,
		// 			spaceBetween: 16,
		// 		},
		// 		1500: {
		// 			slidesPerView:
		// 				item.querySelectorAll(
		// 					".object-slider__swiper .swiper-slide"
		// 				).length > 6
		// 					? 6
		// 					: item.querySelectorAll(
		// 							".object-slider__swiper .swiper-slide"
		// 					  ).length,
		// 			spaceBetween: 16,
		// 		},
		// 	},
		// 	navigation: {
		// 		nextEl: item.querySelector(".object-slider__next"),
		// 		prevEl: item.querySelector(".object-slider__prev"),
		// 	},
		// });
	});
