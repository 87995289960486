import IMask from "imask";
import $ from "jquery";
import "select2";
import "select2/dist/css/select2.css";
import jqueryValidate from "jquery-validation";
// import Swiper bundle with all modules installed
import Swiper from "swiper/bundle";

// import styles bundle
import "swiper/css/bundle";

$(".form1 ").each(function () {
	let th = this;

	let slider = new Swiper(this, {
		spaceBetween: 16,
		slidesPerView: 1,
		allowTouchMove: false,
	});
	$(this)
		.find(".form1__form")
		.validate({
			errorPlacement: function (error, element) {},
			submitHandler: function (form) {
				$(form).submit();
				// $.ajax({
				// 	url: $(form).attr("action"),
				// 	data: {
				// 		data: $(form).serialize(),
				// 	},
				// 	method: "POST",
				// 	headers: {
				// 		"X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr(
				// 			"content"
				// 		),
				// 	},
				// 	context: document.body,
				// 	success: function () {
				// 		slider.slideTo(1);
				// 	},
				// 	error: function () {
				// 		slider.slideTo(2);
				// 	},
				// });
			},
		});
});
$(".feedback-form ").each(function () {
	let th = this;

	let slider = new Swiper(this, {
		spaceBetween: 16,
		slidesPerView: 1,
		allowTouchMove: false,
	});
	console.log($(this).find(".feedback-form__form"));
	$(this)
		.find(".feedback-form__form")
		.validate({
			errorPlacement: function (error, element) {
				console.log("xxx");
			},
			submitHandler: function (form) {
				$(form).submit();
				// $.ajax({
				// 	url: $(form).attr("action"),
				// 	data: {
				// 		data: $(form).serialize(),
				// 	},
				// 	method: "POST",
				// 	headers: {
				// 		"X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr(
				// 			"content"
				// 		),
				// 	},
				// 	context: document.body,
				// 	success: function () {
				// 		slider.slideTo(1);
				// 	},
				// 	error: function () {
				// 		slider.slideTo(2);
				// 	},
				// });
			},
		});
});

$(".select2").select2({
	minimumResultsForSearch: -1,
});
document.querySelectorAll("._mask-phone").forEach(function (element) {
	var maskOptions = {
		mask: "+{7} 000-000-00-00",
	};
	var mask = IMask(element, maskOptions);
});

document.querySelectorAll("._mask-time").forEach(function (element) {
	var maskOptions = {
		mask: "H:M",
		autofix: true,
		blocks: {
			H: {
				mask: IMask.MaskedRange,
				placeholderChar: "H",
				from: 1,
				to: 23,
				maxLength: 2,
			},
			M: {
				mask: IMask.MaskedRange,
				placeholderChar: "M",
				from: 1,
				to: 59,
				maxLength: 2,
			},
		},
	};
	var mask = IMask(element, maskOptions);
});
document.querySelectorAll("._mask-date").forEach(function (element) {
	var maskOptions = {
		mask: Date,

		autofix: true,
		blocks: {
			d: {
				mask: IMask.MaskedRange,
				placeholderChar: "d",
				from: 1,
				to: 31,
				maxLength: 2,
			},
			m: {
				mask: IMask.MaskedRange,
				placeholderChar: "m",
				from: 1,
				to: 12,
				maxLength: 2,
			},
			Y: {
				mask: IMask.MaskedRange,
				placeholderChar: "y",
				from: 2024,
				to: 2999,
				maxLength: 4,
			},
		},
	};
	var mask = IMask(element, maskOptions);
});
document.querySelectorAll("._mask-int").forEach(function (element) {
	var maskOptions = {
		mask: Number,
		min: 0,
		max: 10000,
		thousandsSeparator: " ",
	};
	var mask = IMask(element, maskOptions);
});
