// import Swiper bundle with all modules installed
import Swiper from "swiper/bundle";

// import styles bundle
import "swiper/css/bundle";

document
	.querySelectorAll(" body:not(.is-admin) .news-slider")
	.forEach(function (item) {
		let slider = new Swiper(item.querySelector(".news-slider__swiper"), {
			slidesPerView: 1,
			spaceBetween: 8,
			breakpoints: {
				719: {
					slidesPerView: 2,
					spaceBetween: 12,
				},

				1300: {
					slidesPerView: 3,
					spaceBetween: 10,
				},

				1500: {
					slidesPerView: 4,
					spaceBetween: 16,
				},
			},
			navigation: {
				nextEl: item.querySelector(".news-slider__next"),
				prevEl: item.querySelector(".news-slider__prev"),
			},
		});
	});
