// import Swiper bundle with all modules installed
import Swiper from "swiper/bundle";

// import styles bundle
import "swiper/css/bundle";

document
	.querySelectorAll("body:not(.is-admin) .events-slider")
	.forEach(function (item) {
		let slider = new Swiper(item.querySelector(".events-slider__swiper"), {
			slidesPerView: 1,
			spaceBetween: 8,
			breakpoints: {
				600: { slidesPerView: 2, spaceBetween: 12 },

				992: {
					slidesPerView: 3,
				},

				1100: { slidesPerView: 3, spaceBetween: 10 },
				1500: {
					spaceBetween: 16,
					slidesPerView: 3,
				},
			},
			navigation: {
				nextEl: item.querySelector(".events-slider__next"),
				prevEl: item.querySelector(".events-slider__prev"),
			},
		});
	});
