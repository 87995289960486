// import Swiper bundle with all modules installed
import Swiper from "swiper/bundle";

// import styles bundle
import "swiper/css/bundle";

document
	.querySelectorAll("body:not(.is-admin) .entertaiment-slider")
	.forEach(function (item) {
		new Swiper(item.querySelector(".entertaiment-slider__swiper"), {
			spaceBetween: 12,
			slidesPerView: 2,
			breakpoints: {
				600: {
					slidesPerView: 2.659,
					spaceBetween: 12,
				},
				992: {
					slidesPerView: 4,
					spaceBetween: 12,
				},
				1500: { spaceBetween: 12, slidesPerView: 4, spaceBetween: 16 },
			},
			navigation: {
				nextEl: item.querySelector(".entertaiment-slider__next"),
				prevEl: item.querySelector(".entertaiment-slider__prev"),
			},
		});
	});
