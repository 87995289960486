// import Swiper bundle with all modules installed
import Swiper from "swiper/bundle";

// import styles bundle
import "swiper/css/bundle";

document
	.querySelectorAll("body:not(.is-admin) .special-slider")
	.forEach(function (item) {
		let slider = new Swiper(item.querySelector(".special-slider__swiper"), {
			slidesPerView: 1,
			spaceBetween: 8,
			breakpoints: {
				600: {
					spaceBetween: 12,
					slidesPerView: 2,
				},

				992: {
					slidesPerView: 3,
				},

				1100: {
					slidesPerView: 4,
					spaceBetween: 10,
				},
			},
			navigation: {
				nextEl: item.querySelector(".special-slider__next"),
				prevEl: item.querySelector(".special-slider__prev"),
			},
		});
	});
document
	.querySelectorAll(" body:not(.is-admin) .special-slider2")
	.forEach(function (item) {
		let translate = 0;

		let sliderBlock = item.querySelector(".special-slider2__swiper");
		let slider = new Swiper(
			item.querySelector(".special-slider2__swiper"),
			{
				slidesPerView: 1,
				speed: 500,
				observer: true,
				observeParents: true,
				observeSlideChildren: true,
				spaceBetween: 8,
				loopAdditionalSlides: 10,
				watchSlidesVisibility: true,
				watchSlidesProgress: true,
				breakpoints: {
					719: { slidesPerView: 2, spaceBetween: 12 },

					992: {
						slidesPerView: 3,
						spaceBetween: 10,
					},
					1100: {
						slidesPerView: 4,
						spaceBetween: 10,
					},

					1400: {
						slidesPerView: 5,
						spaceBetween: 10,
					},
				},
				navigation: {
					nextEl: item.querySelector(".special-slider2__next"),
					prevEl: item.querySelector(".special-slider2__prev"),
				},
			}
		);

		// slider.on("touchStart", function () {
		// 	sliderBlock.classList.add("_transition");
		// 	// document.querySelector(".special-slider2__swiper").swiper.update();
		// });
		// slider.on("touchEnd", function () {
		// 	sliderBlock.classList.remove("_transition");
		// 	// document.querySelector(".special-slider2__swiper").swiper.update();
		// });
		slider.on("beforeTransitionStart", function () {
			sliderBlock.classList.add("_transition");
		});
		slider.on("transitionEnd", function () {
			sliderBlock.classList.remove("_transition");
		});
		slider.on("touchStart", function () {
			sliderBlock.classList.add("_transition");
		});
		slider.on("touchEnd", function () {
			sliderBlock.classList.remove("_transition");
		});
	});
// document.querySelectorAll(".special-slider2").forEach(function (item) {
// 	let translate = 0;

// 	let sliderBlock = item.querySelector(".special-slider2__swiper");
// 	let slider = new Swiper(item.querySelector(".special-slider2__swiper"), {
// 		slidesPerView: 1,

// 		freeMode: true,
// 		observer: true,
// 		observeParents: true,
// 		observeSlideChildren: true,
// 		spaceBetween: 8,
// 		loopAdditionalSlides: 10,
// 		watchSlidesVisibility: true,
// 		watchSlidesProgress: true,
// 		breakpoints: {
// 			719: { slidesPerView: 2, spaceBetween: 12 },

// 			992: {
// 				slidesPerView: 2.5,
// 				spaceBetween: 10,
// 			},
// 		},
// 		navigation: {
// 			nextEl: item.querySelector(".special-slider2__next"),
// 			prevEl: item.querySelector(".special-slider2__prev"),
// 		},
// 	});

// 	slider.on("progress", function () {
// 		// setTimeout(function () {
// 		let activeFlag = false;
// 		let order = 0;
// 		let diff1 =
// 			slider.slides[slider.activeIndex].offsetWidth -
// 			slider.slides[slider.activeIndex].querySelector(".spec-preview")
// 				.offsetWidth;
// 		let diff2 =
// 			slider.slides[slider.activeIndex + 1].querySelector(".spec-preview")
// 				.offsetWidth -
// 			slider.slides[slider.activeIndex + 1].offsetWidth / 2;
// 		console.log("diff1", diff1);
// 		console.log("diff2", diff2);
// 		slider.slides.forEach(function (slide) {
// 			let progress = slide.progress;
// 			slide.style.setProperty("--progress", progress);
// 			if (progress > -1 && progress < 1) {
// 				slide.style.setProperty(
// 					"--mwidth",
// 					50 + (50 - Math.abs(progress) * 50) + "%"
// 				);
// 				diff1 =
// 					slider.slides[slider.activeIndex].offsetWidth -
// 					slider.slides[slider.activeIndex].querySelector(
// 						".spec-preview"
// 					).offsetWidth;
// 				console.log();
// 				slide.style.setProperty(
// 					"--translate",
// 					(diff1 +
// 						(slide.offsetWidth -
// 							slide.querySelector(".spec-preview").offsetWidth) /
// 							2) *
// 						-Math.abs(progress)
// 				);
// 			} else {
// 				slide.style.setProperty("--mwidth", "50%");
// 				if (progress < -1) {
// 					slide.style.setProperty(
// 						"--translate",
// 						(slide.offsetWidth / 2) * (progress + 1).toFixed() -
// 							slide.offsetWidth / 4 -
// 							diff1 -
// 							diff2
// 					);
// 				} else {
// 					slide.style.setProperty(
// 						"--translate",
// 						(slide.offsetWidth / 2) * (progress + 1).toFixed() -
// 							slide.offsetWidth / 4 -
// 							diff1
// 					);
// 				}
// 			}

// 			slide.style.setProperty("--order", progress.toFixed());
// 			if (progress <= -1 && progress >= -2) {
// 				// slide.style.setProperty(
// 				// "--translate",
// 				// (slide.offsetWidth / 2) * (progress + 1).toFixed() -
// 				// 	slide.offsetWidth / 4)
// 			}
// 			if (progress <= -1) {
// 			}
// 			// if (progress <= -1 && progress >= -2) {
// 			// 	translate =
// 			// 		slide.offsetWidth +
// 			// 		10 -
// 			// 		(slide.offsetWidth + 10) * Math.abs(progress);
// 			// 	mwidth = (slide.offsetWidth + translate).toFixed(2);
// 			// 	slide.style.setProperty("--translate", translate);
// 			// }
// 			// slide.style.setProperty("--translate", translate);
// 			// console.log(item);
// 			// if (activeFlag) {
// 			// 	item.classList.add("_next");
// 			// 	item.classList.remove("_post");
// 			// 	item.style.setProperty("--order", order);
// 			// 	item.style.setProperty(
// 			// 		"--translate",
// 			// 		(item.offsetWidth / 2) * order + "px"
// 			// 	);
// 			// 	order++;
// 			// } else {
// 			// 	item.classList.add("_post");
// 			// 	item.classList.remove("_next");
// 			// 	item.style.setProperty("--translate", item.offsetWidth / 2);
// 			// }
// 			// if (item.classList.contains("swiper-slide-active")) {
// 			// 	activeFlag = true;
// 			// 	order = 0;
// 			// }
// 			// slide.style.setProperty("--translate", translate);
// 			// slide.style.setProperty("--mwidth", mwidth + "px");

// 			// item.style.setProperty("--progress", progress);
// 			// item.style.setProperty("--translate", translate);
// 			// item.style.setProperty("--active", active);
// 		});
// 		// slider.slides.forEach(function (slide) {
// 		// 	let progress = slide.progress;
// 		// 	slide.style.setProperty("--progress", progress);
// 		// 	if (progress > -1 && progress < 1) {
// 		// 		slide.style.setProperty(
// 		// 			"--mwidth",
// 		// 			50 + (50 - Math.abs(progress) * 50) + "%"
// 		// 		);
// 		// 		slide.style.setProperty(
// 		// 			"--translate",
// 		// 			(slides[slider.activeIndex].offsetWidth -
// 		// 				slides[slider.activeIndex].querySelector(
// 		// 					".spec-preview"
// 		// 				).offsetWidth +
// 		// 				slides[slider.activeIndex](
// 		// 					slide.offsetWidth -
// 		// 						slide.querySelector(".spec-preview").offsetWidth
// 		// 				) /
// 		// 					-2) *
// 		// 				Math.abs(progress)
// 		// 		);
// 		// 	} else {
// 		// 		slide.style.setProperty("--mwidth", "50%");
// 		// 		slide.style.setProperty(
// 		// 			"--translate",
// 		// 			(slide.offsetWidth / 2) * (progress + 1).toFixed() -
// 		// 				slide.offsetWidth / 4
// 		// 		);
// 		// 	}

// 		// 	slide.style.setProperty("--order", progress.toFixed());
// 		// 	if (progress <= -1 && progress >= -2) {
// 		// 		// slide.style.setProperty(
// 		// 		// "--translate",
// 		// 		// (slide.offsetWidth / 2) * (progress + 1).toFixed() -
// 		// 		// 	slide.offsetWidth / 4)
// 		// 	}
// 		// 	if (progress <= -2) {
// 		// 	}
// 		// 	// if (progress <= -1 && progress >= -2) {
// 		// 	// 	translate =
// 		// 	// 		slide.offsetWidth +
// 		// 	// 		10 -
// 		// 	// 		(slide.offsetWidth + 10) * Math.abs(progress);
// 		// 	// 	mwidth = (slide.offsetWidth + translate).toFixed(2);
// 		// 	// 	slide.style.setProperty("--translate", translate);
// 		// 	// }
// 		// 	// slide.style.setProperty("--translate", translate);
// 		// 	// console.log(item);
// 		// 	// if (activeFlag) {
// 		// 	// 	item.classList.add("_next");
// 		// 	// 	item.classList.remove("_post");
// 		// 	// 	item.style.setProperty("--order", order);
// 		// 	// 	item.style.setProperty(
// 		// 	// 		"--translate",
// 		// 	// 		(item.offsetWidth / 2) * order + "px"
// 		// 	// 	);
// 		// 	// 	order++;
// 		// 	// } else {
// 		// 	// 	item.classList.add("_post");
// 		// 	// 	item.classList.remove("_next");
// 		// 	// 	item.style.setProperty("--translate", item.offsetWidth / 2);
// 		// 	// }
// 		// 	// if (item.classList.contains("swiper-slide-active")) {
// 		// 	// 	activeFlag = true;
// 		// 	// 	order = 0;
// 		// 	// }
// 		// 	// slide.style.setProperty("--translate", translate);
// 		// 	// slide.style.setProperty("--mwidth", mwidth + "px");

// 		// 	// item.style.setProperty("--progress", progress);
// 		// 	// item.style.setProperty("--translate", translate);
// 		// 	// item.style.setProperty("--active", active);
// 		// });
// 	});
// });
// });
// let translate = 0;
// let progress = parseFloat(item.progress.toFixed(2));
// let active = 0;
// if (progress >= -1 && progress <= 1) {
// 	active = progress + 1;
// }
// if (progress > -1 && progress < 1) {
// 	translate =
// 		(progress *
// 			(item.offsetWidth -
// 				item.querySelector(".spec-preview").offsetWidth)) /
// 		2;
// } else {
// 	translate =
// 		(progress + 1) *
// 		(item.offsetWidth -
// 			item.querySelector(".spec-preview").offsetWidth);
// }
